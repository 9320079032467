import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SwanAIRequestInterface, SwanAIResponseInterface } from '../interfaces/swan-ai.interface';
import { SWAN_AI_API_URL } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class SwanAIService {
  constructor(
    private readonly httpClient: HttpClient,
  ) { }

  async processSnapshot(base64Image: string): Promise<SwanAIResponseInterface> {
    const body: SwanAIRequestInterface = {
      bucket: 'fd-image-input',
      currentDevId: 'AmwellDevice',
      base64_image: base64Image
    };
    return this.httpClient
      .post<SwanAIResponseInterface>(`${SWAN_AI_API_URL}`,
        body,
        {
          params: {
            graphics: 'false'
          }
        }).toPromise();
  }
}
